
const componentLetterEntropies = {
  "noun-animate": {
    "entropy": 4.234479331571937,
    "letterEntropies": {
      "D": 10,
      "o": 5.245112497836532,
      "a": 4.385290155884792,
      "b": 3.8707169830550336,
      "c": 3.117356950638159,
      "C": 10,
      "d": 4.272079545436801,
      "e": 5.045803689613125,
      "f": 4.714597781137751,
      "g": 4.245112497836532,
      "h": 4.540568381362703,
      "i": 5.830074998557688,
      "j": 6.093109404391481,
      "k": 6.678071905112637,
      "l": 4.476438043942987,
      "m": 3.771181309504119,
      "p": 3.4608411888919686,
      "P": 9,
      "r": 4.356143810225276,
      "s": 3.299560281858908,
      "v": 5.678071905112637,
      "y": 7.678071905112637,
      "n": 6.093109404391481,
      "t": 4.607682577221239,
      "u": 8.415037499278844,
      "w": 4.912537158749661,
      "q": 8.415037499278844,
      "z": 9
    }
  },
  "noun-concrete": {
    "entropy": 4.160289155060128,
    "letterEntropies": {
      "C": 10,
      "P": 10,
      "a": 5.045803689613125,
      "A": 10,
      "b": 3.299560281858908,
      "c": 3.0458036896131246,
      "d": 4.678071905112637,
      "e": 6,
      "f": 4.607682577221239,
      "h": 4.385290155884792,
      "j": 6.415037499278844,
      "k": 5.752072486556415,
      "l": 4.714597781137751,
      "m": 4,
      "n": 6.299560281858908,
      "o": 5.476438043942987,
      "p": 3.4922053598013036,
      "r": 4.540568381362703,
      "s": 3.1420190048724277,
      "S": 10,
      "t": 3.830074998557688,
      "u": 7.415037499278844,
      "v": 6.830074998557688,
      "w": 5.045803689613125,
      "y": 7.192645077942396,
      "g": 4.385290155884792,
      "i": 6.192645077942396,
      "z": 8.415037499278844,
      "q": 10,
      "I": 10
    }
  },
  "noun-concrete-plural": {
    "entropy": 4.160289155060128,
    "letterEntropies": {
      "C": 10,
      "P": 10,
      "a": 5.045803689613125,
      "A": 10,
      "b": 3.299560281858908,
      "c": 3.0458036896131246,
      "d": 4.678071905112637,
      "e": 6,
      "f": 4.607682577221239,
      "h": 4.385290155884792,
      "j": 6.415037499278844,
      "k": 5.752072486556415,
      "l": 4.714597781137751,
      "m": 4,
      "n": 6.299560281858908,
      "o": 5.476438043942987,
      "p": 3.4922053598013036,
      "r": 4.540568381362703,
      "s": 3.1420190048724277,
      "S": 10,
      "t": 3.830074998557688,
      "u": 7.415037499278844,
      "v": 6.830074998557688,
      "w": 5.045803689613125,
      "y": 7.192645077942396,
      "g": 4.385290155884792,
      "i": 6.192645077942396,
      "z": 8.415037499278844,
      "q": 10,
      "I": 10
    }
  },
  "verb": {
    "entropy": 4.108783453821568,
    "letterEntropies": {
      "h": 4.540568381362703,
      "g": 4.752072486556415,
      "s": 2.5906090638622983,
      "w": 4.830074998557688,
      "c": 3.678071905112638,
      "l": 4.540568381362703,
      "b": 4,
      "e": 5,
      "m": 4.912537158749661,
      "t": 4.093109404391481,
      "f": 4.607682577221239,
      "k": 6.415037499278844,
      "o": 6,
      "n": 6.678071905112637,
      "v": 7.415037499278844,
      "d": 3.9125371587496605,
      "r": 3.642447995381916,
      "p": 3.5081469036703252,
      "i": 5.415037499278844,
      "a": 4.912537158749661,
      "j": 6.415037499278844,
      "u": 5.540568381362703,
      "z": 9,
      "y": 9
    }
  },
  "verb-s": {
    "entropy": 4.108783453821568,
    "letterEntropies": {
      "h": 4.540568381362703,
      "g": 4.752072486556415,
      "s": 2.5906090638622983,
      "w": 4.830074998557688,
      "c": 3.678071905112638,
      "l": 4.540568381362703,
      "b": 4,
      "e": 5,
      "m": 4.912537158749661,
      "t": 4.093109404391481,
      "f": 4.607682577221239,
      "k": 6.415037499278844,
      "o": 6,
      "n": 6.678071905112637,
      "v": 7.415037499278844,
      "d": 3.9125371587496605,
      "r": 3.642447995381916,
      "p": 3.5081469036703252,
      "i": 5.415037499278844,
      "a": 4.912537158749661,
      "j": 6.415037499278844,
      "u": 5.540568381362703,
      "z": 9,
      "y": 9
    }
  },
  "verb-ed": {
    "entropy": 4.108783453821568,
    "letterEntropies": {
      "h": 4.540568381362703,
      "g": 4.752072486556415,
      "s": 2.5906090638622983,
      "w": 4.830074998557688,
      "c": 3.678071905112638,
      "l": 4.540568381362703,
      "b": 4,
      "e": 5,
      "m": 4.912537158749661,
      "t": 4.093109404391481,
      "f": 4.607682577221239,
      "k": 6.415037499278844,
      "o": 6,
      "n": 6.678071905112637,
      "v": 7.415037499278844,
      "d": 3.9125371587496605,
      "r": 3.642447995381916,
      "p": 3.5081469036703252,
      "i": 5.415037499278844,
      "a": 4.912537158749661,
      "j": 6.415037499278844,
      "u": 5.540568381362703,
      "z": 9,
      "y": 9
    }
  },
  "adj": {
    "entropy": 4.237908227142307,
    "letterEntropies": {
      "n": 5.678071905112637,
      "o": 5.356143810225276,
      "b": 4.327574658028505,
      "r": 4.093109404391481,
      "h": 4.508146903670325,
      "d": 4.272079545436801,
      "w": 4.955605880641547,
      "i": 4.830074998557688,
      "t": 4.445411148322362,
      "g": 4.912537158749661,
      "c": 3.8707169830550336,
      "s": 2.8003276551636356,
      "p": 4,
      "f": 3.7905466343710503,
      "m": 4.167109985835259,
      "l": 4.508146903670325,
      "a": 4.714597781137751,
      "j": 7.678071905112637,
      "y": 7.678071905112637,
      "e": 4.642447995381916,
      "v": 5.752072486556415,
      "u": 4.445411148322362,
      "q": 10,
      "k": 7.415037499278844
    }
  },
  "adj-animate": {
    "entropy": 4.876789449392538,
    "letterEntropies": {
      "d": 3.771181309504119,
      "e": 5,
      "l": 4.912537158749661,
      "b": 4.508146903670325,
      "c": 4.045803689613125,
      "k": 7.415037499278844,
      "t": 4.870716983055034,
      "q": 7.678071905112637,
      "u": 4.573735245297902,
      "f": 4.445411148322362,
      "r": 4.678071905112637,
      "a": 4.069262662437113,
      "m": 4.752072486556415,
      "g": 5.356143810225276,
      "s": 3.2857544823338776,
      "i": 4.830074998557688,
      "p": 4.573735245297902,
      "h": 4.607682577221239,
      "w": 4.912537158749661,
      "v": 5.830074998557688,
      "n": 5.752072486556415,
      "j": 6.830074998557688,
      "z": 9,
      "o": 5.356143810225276,
      "y": 8.415037499278844,
      "A": 5.912537158749661,
      "T": 7.678071905112637,
      "Z": 9,
      "I": 7,
      "L": 7.415037499278844,
      "R": 8.415037499278844,
      "D": 8.415037499278844,
      "G": 7.678071905112637,
      "C": 6.093109404391481,
      "S": 6.299560281858908,
      "H": 7.192645077942396,
      "W": 10,
      "M": 7,
      "F": 7.678071905112637,
      "K": 8,
      "P": 7.192645077942396,
      "B": 6.415037499278844,
      "E": 7.678071905112637,
      "J": 8,
      "Y": 8.415037499278844,
      "N": 8.415037499278844,
      "U": 9,
      "O": 10,
      "V": 10
    }
  },
  "number": {
    "entropy": 10,
    "letterEntropies": {}
  },
  "place": {
    "entropy": 4.382581103035223,
    "letterEntropies": {
      "L": 4.476438043942987,
      "N": 4.192645077942396,
      "O": 6,
      "I": 4.912537158749661,
      "U": 6.415037499278844,
      "M": 3.3275746580285044,
      "C": 4.093109404391481,
      "T": 4.245112497836532,
      "P": 4.415037499278844,
      "F": 5.830074998557688,
      "G": 4.540568381362703,
      "A": 3.7145977811377517,
      "R": 5.678071905112637,
      "E": 5.540568381362703,
      "K": 4.607682577221239,
      "J": 5.830074998557688,
      "Y": 8,
      "S": 3.356143810225275,
      "B": 3.678071905112638,
      "D": 4.912537158749661,
      "H": 4.678071905112637,
      "Q": 7,
      "V": 5.678071905112637,
      "W": 5.415037499278844,
      "Z": 6.415037499278844,
      "t": 5
    }
  }
};

export default componentLetterEntropies;